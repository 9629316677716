import React, { useMemo, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputRightElement,
  Radio,
  RadioGroup,
  Select,
  Spinner,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { Shelf } from 'components/ShelfHelper/Shelf';
import { useTranslation } from 'react-i18next';
import { Card, CardTitleSection } from 'components/Card';
import { UniqueProductsSelect } from './UniqueProductsSelect';
import { ShelfSizeSelect } from './ShelfSizeSelect';
import { EuroSign } from '../Icons/icons';
import { postInit, TInitiaShelfProducts, TScenarioResponseValidatorDetails, TShelfProduct } from 'domain/shelf-helper';
import { useColorManagement } from './hooks/useColorManagement';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { createShelfHelperShelvesKey } from 'constants/queryCacheKeys';
import * as PATHS from 'constants/paths';

const INIT_SHELF_PRODUCT = [
  {
    name: 'Product 1',
    discount: 0,
    org_price: 5,
    org_margin: 1,
  },
  {
    name: 'Product 2',
    discount: 0,
    org_price: 5,
    org_margin: 1,
  },
  {
    name: 'Product 3',
    discount: 0,
    org_price: 5,
    org_margin: 1,
  },
  {
    name: 'Product 4',
    discount: 0,
    org_price: 5,
    org_margin: 1,
  },
];

export const ShelfHelperInit: React.FC = () => {
  const { t } = useTranslation('whisperme');
  const queryClient = useQueryClient();
  const toast = useToast();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [experimentUUID, setExperimentUUID] = useState<string>('');
  const [numberOfProducts, setNumberOfProducts] = useState<number>(INIT_SHELF_PRODUCT.length);
  const [products, setProducts] = useState<TInitiaShelfProducts>(INIT_SHELF_PRODUCT);
  const [shelfSize, setShelfSize] = useState<string>('40x5');
  const [optimizeFor, setOptimizeFor] = useState<string>('number_of_sold_units');
  const [includeDiscounts, setIncludeDiscounts] = useState<string>('0');
  const [duration, setDuration] = useState<number>(4);
  const [data, setData] = useState<TScenarioResponseValidatorDetails>(
    {} as unknown as TScenarioResponseValidatorDetails,
  );
  const history = useHistory();
  const colorMapping = useColorManagement({ data });
  const goBackLink = PATHS.SHELF_HELPER.ROOT.toPath();

  const handleInit = () => {
    const payload = {
      no_of_product: numberOfProducts,
      shelf_size: shelfSize,
      optiomalize: optimizeFor,
      discounts: !!(includeDiscounts === '1'),
      duration,
      products: products.map((product) => ({
        ...product,
        org_margin: 1,
      })),
    };
    setIsLoading(true);
    postInit(payload)
      .then((response) => {
        if (response.experiment_id && response.response) {
          setData(response.response);
          setExperimentUUID(response.experiment_id);
          queryClient.invalidateQueries(createShelfHelperShelvesKey());
          history.push(`/shelf-helper/step?experimentId=${response.experiment_id}`);
        }
      })
      .catch((err) => {
        console.log('err', err);
        toast({
          title: t('COMPONENTS.ERROR_MESSAGES.PROMPT_TITLE_ERROR'),
          description: t('SHELF_HELPER.ERROR_MESSAGES.EXPERIMENT_INIT_ERROR'),
          status: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleNumberOfProductsChange = (newNumberOfProducts: number) => {
    if (newNumberOfProducts > numberOfProducts) {
      const howManyNewElements = newNumberOfProducts - numberOfProducts;
      const diffProducts = Array.from({ length: howManyNewElements }, (_, index) => {
        return {
          name: `Product ${numberOfProducts + index + 1}`,
          discount: includeDiscounts === '1' ? 10 : 0,
          org_price: 5,
          org_margin: 1,
        };
      });
      setProducts((prev) => [...prev, ...diffProducts]);
    } else {
      setProducts((_prev) => products.slice(0, newNumberOfProducts));
    }
    setNumberOfProducts(newNumberOfProducts);
  };

  const handleProductChange = (index: number, field: keyof TShelfProduct, value: any) => {
    setProducts((prevProducts) =>
      prevProducts.map((product, i) => (i === index ? { ...product, [field]: value } : product)),
    );
  };

  const tableColumns = useMemo(() => {
    if (optimizeFor === 'revenue') return '2fr 1fr 1fr';
    return '2fr 1fr';
  }, [optimizeFor]);

  return (
    <Card>
      <CardTitleSection as="h2" backButtonLink={goBackLink}>
        {t('SHELF_HELPER.ROOT_LABEL')}
      </CardTitleSection>

      <Grid templateColumns="1fr" width="100%">
        <Shelf experimentUUID={experimentUUID} shelfSize={shelfSize} data={data} colorMapping={colorMapping} />
      </Grid>

      <Grid templateColumns="9fr 3fr" width="100%">
        <Box mt="3">
          <Grid templateColumns="2fr 2fr" gap={3}>
            <UniqueProductsSelect
              disabled={isLoading}
              numberOfProducts={numberOfProducts}
              setNumberOfProducts={handleNumberOfProductsChange}
            />
            <ShelfSizeSelect shelfSize={shelfSize} setShelfSize={setShelfSize} disabled={isLoading} />
          </Grid>

          <Grid templateColumns={tableColumns} gap={3} mt="3">
            {experimentUUID.length ? <GridItem></GridItem> : null}
            <GridItem as="strong">{t('SHELF_HELPER.PRODUCT_NAME_LABEL')}</GridItem>
            {optimizeFor === 'revenue' ? <GridItem as="strong">{t('SHELF_HELPER.MARGIN_LABEL')}</GridItem> : null}
            <GridItem as="strong">{t('SHELF_HELPER.PRODUCT_ORIGINAL_PRICE_LABEL')}</GridItem>

            {products.map((item, index) => (
              <React.Fragment key={`product_${index}`}>
                <GridItem>
                  <Input
                    type="text"
                    value={products[index].name}
                    disabled={isLoading}
                    onChange={(e) => handleProductChange(index, 'name', e.target.value)}></Input>
                </GridItem>
                {optimizeFor === 'revenue' ? (
                  <GridItem>
                    <InputGroup>
                      <Input
                        type="number"
                        value={products[index].org_margin}
                        disabled={isLoading}
                        onChange={(e) =>
                          handleProductChange(
                            index,
                            'org_margin',
                            e.target.value ?? (parseInt(e.target.value, 10) || '1'),
                          )
                        }></Input>{' '}
                      <InputRightElement pointerEvents="none">
                        <EuroSign />
                      </InputRightElement>
                    </InputGroup>
                  </GridItem>
                ) : null}
                <GridItem>
                  <InputGroup>
                    <Input
                      type="number"
                      value={products[index].org_price}
                      disabled={isLoading}
                      onChange={(e) =>
                        handleProductChange(index, 'org_price', e.target.value ?? (parseInt(e.target.value, 10) || '0'))
                      }></Input>{' '}
                    <InputRightElement pointerEvents="none">
                      <EuroSign />
                    </InputRightElement>
                  </InputGroup>
                </GridItem>
              </React.Fragment>
            ))}
          </Grid>
        </Box>

        <Box ml="5" pl="3" borderLeft="1px" borderColor="gray.200">
          <Box>
            <Text as="strong">{t('SHELF_HELPER.OPTIMIZE_LABEL')}</Text>
            <RadioGroup
              defaultValue="number_of_sold_units"
              onChange={setOptimizeFor}
              value={optimizeFor}
              mt="1"
              isDisabled={isLoading}>
              <VStack gap="2" textAlign="left" alignItems="flex-start" alignContent="flex-start">
                <Radio value="number_of_sold_units">{t('SHELF_HELPER.NUMBER_OF_SOLD_UNITS_LABEL')}</Radio>
                <Radio value="sales">{t('SHELF_HELPER.SALES_LABEL')}</Radio>
                <Radio value="revenue">{t('SHELF_HELPER.MARGIN_LABEL')}</Radio>
              </VStack>
            </RadioGroup>
          </Box>

          <Box mt="3">
            <Text as="strong">{t('SHELF_HELPER.INCLUDE_DISCOUNTS_LABEL')}</Text>
            <RadioGroup
              defaultValue="0"
              onChange={setIncludeDiscounts}
              value={includeDiscounts}
              mt="1"
              isDisabled={isLoading}>
              <VStack gap="2" textAlign="left" alignItems="flex-start" alignContent="flex-start">
                <Radio value="0">{t('SHELF_HELPER.NO_LABEL')}</Radio>
                <Radio value="1">{t('SHELF_HELPER.YES_LABEL')}</Radio>
              </VStack>
            </RadioGroup>
          </Box>

          <Box mt="3">
            <Text as="strong">{t('SHELF_HELPER.DURATION_LABEL')}</Text>
            <Select
              placeholder={t('SHELF_HELPER.DURATION_LABEL')}
              disabled={isLoading}
              onChange={(e) => {
                setDuration(parseInt(e.target.value, 10));
              }}
              value={duration}
              mt="1">
              <option value="4">4 weeks</option>
              <option value="8">8 weeks</option>
              <option value="16">16 weeks</option>
            </Select>
          </Box>
        </Box>
      </Grid>
      <Flex mt="3" width="100%" justifyContent="flex-end">
        {isLoading ? <Spinner marginRight="16px" marginTop="4px" /> : null}
        <Button
          colorScheme="blue"
          size="sm"
          disabled={isLoading}
          onClick={() => {
            handleInit();
          }}>
          {t('SHELF_HELPER.START_OPTIMIZATION_BUTTON_LABEL')}
        </Button>
      </Flex>
    </Card>
  );
};
