import { createShelfPredictionKey } from 'constants/queryCacheKeys';
import { postNextShelfPredictions, TPostNextShelfHelperParams } from 'domain/shelf-helper';
import { useQuery } from 'react-query';

export const useShelfPrediction = (params: TPostNextShelfHelperParams) => {
  const isEnabled = !!(params.experiment_id ?? '').length;

  return useQuery({
    queryKey: createShelfPredictionKey(params),
    queryFn: () => postNextShelfPredictions(params),
    enabled: isEnabled,
  });
};
