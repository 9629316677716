import { GetClusterMapStorePositionsParams, GetMapCharacteristicsParams } from 'domain/map';
import {
  GetReceiptErrorListParams,
  GetStoreProductStatisticsParams,
  GetStoreStatisticsParams,
  GetReceiptSourceLinkParams,
} from 'domain/statistics';
import {
  TAssortmentStatisticsParams,
  TGetGoodToHaveForStores,
  TGetProductDetailsParams,
  TGetProductListParams,
  TProductAnomaliesRequestQueryParams,
  TProductStatisticsRequestQueryParams,
  TWeeklyProductManagementParams,
} from 'domain/products';
import { GetRoleByIdParams, DeleteRoleParams } from 'domain/roles';
import {
  TGetAnomalyCalendarParams,
  TGetLogisticAnomaliesParams,
  TGetSalesAnomaliesParams,
  TGetTopAnomalousParams,
  TGetSectionAnomaliesParams,
} from 'domain/objectives';
import { TGetInsightsParams } from 'domain/insights';
import {
  TGetCustomerLabelsParams,
  TGetCustomersChurnParams,
  TGetCustomersLostGroupsParams,
  TGetCustomersMapParams,
  TGetCustomersRetentionRateParams,
  TGetCustomersRiskParams as TGetCustomerGroupsRiskParams,
  TGetCustomerTiersParams,
  TGetTopCustomersParams,
  TPostCreateCustomersMapExportParams,
} from 'domain/customer';
import { TGetObjectiveParams, TGetAllObjectivesParams } from 'domain/objectives';
import { TGetUserDetailsParams, TDeleteUserParams } from 'domain/user';
import { SalesWatchlistStatisticsParams, TSalesWatchlistId } from 'domain/sales-watchlist';
import {
  CashierPredictionParams,
  SectionPredictionParams,
  OrdersParams,
  SalesPredictionParams,
} from 'domain/predictions';
import { StocksPointsParams } from 'domain/stocks';
import {
  Commons,
  GetStockProductHistory,
  GetStocksForStore,
  ShortageProductPointsParams,
  ShortageProductsListParams,
  IndividualShortagesChartPointsParams,
} from 'domain/stocks';
import { TFamilyCode } from 'domain/core';
import { TNeochatExampleQueriesParams } from 'domain/neochat';
import { FindProductsParams, GetCustomersSimilarityParams, GetHistoryParams } from 'domain/neoemail/types';
import { TGetSimilarStoresParams } from 'domain/stores';
import { DiscountsListFilterParams } from 'domain/discounts';
import { TGetFinalizeShelfHelperParams, TPostNextShelfHelperParams } from 'domain/shelf-helper';

export const createStoreStatisticsKey = ({
  storeIds,
  statisticIds,
  resolution,
  date_from,
  date_to,
}: GetStoreStatisticsParams) => ['stores', storeIds, statisticIds, date_from, date_to, resolution];

export const createReceiptErrorListKey = ({ start_time, end_time, store_ids }: GetReceiptErrorListParams) => [
  'receipt-errors',
  start_time,
  end_time,
  store_ids,
];

export const createReceiptSourceLinkKey = (error_id: GetReceiptSourceLinkParams) => ['failed-receipt-link', error_id];

export const createStoreProductStatisticsKey = ({
  storeIds,
  column_sort,
  order,
  family_filters,
  section_filters,
  size,
  page,
  date_from,
  date_to,
}: GetStoreProductStatisticsParams) => [
  'store-product-statistics',
  storeIds,
  column_sort,
  order,
  ...(section_filters ? section_filters : [undefined]),
  ...(family_filters ? family_filters : [undefined]),
  date_from,
  date_to,
  size,
  page,
];

export const createProductDetailsKey = ({ productCode }: TGetProductDetailsParams) => ['product-details', productCode];
export const createProductFullDetailsKey = ({ productCode }: TGetProductDetailsParams) => [
  'product-full-details',
  productCode,
];

export const createClusterMapKey = () => ['cluster-map'];
export const createMapLayersKey = () => [...createClusterMapKey(), 'layers'];

export const createClusterMapStorePositionsKey = (params?: GetClusterMapStorePositionsParams) => [
  ...createClusterMapKey(),
  'store-positions',
  params,
];

export const createClusterMapGroupsKey = () => [...createClusterMapKey(), 'cluster-groups'];
export const createClusterMapGoalsKey = () => [...createClusterMapKey(), 'goals'];
export const createClusterMapMeanPositionsKey = () => [...createClusterMapKey(), 'mean-positions'];
export const createClusterMapStoresCharacteristicsKey = (params: GetMapCharacteristicsParams) => [
  ...createClusterMapKey(),
  'store-characteristics',
  params,
];

export const createRolesListKey = () => ['roles', 'list'];
export const createRoleDetailsKey = ({ roleId }: GetRoleByIdParams) => ['roles', 'detail', roleId];

export const createRoleDeleteKey = ({ roleId }: DeleteRoleParams) => ['deleted-role', roleId];

export const createProductsListKey = () => ['products', 'list'];
export const createProductsListParamKey = (params: TGetProductListParams) => [...createProductsListKey(), params];

export const createProductFamiliesKey = () => ['product-catalog'];

export const createObjectivesKey = () => ['objectives'];
export const createSaleAnomaliesKey = (params: TGetSalesAnomaliesParams) => [
  ...createObjectivesKey(),
  'sale-anomalies',
  params,
];

export const createTopAnomalousKey = (params: TGetTopAnomalousParams) => [
  ...createObjectivesKey(),
  'sale-top-anomalous',
  params,
];

export const createSectionsAnomaliesKey = (params: TGetSectionAnomaliesParams) => [
  ...createObjectivesKey(),
  'sections-anomalies',
  params,
];

export const createAnomalyCalendarKey = ({ store_id, ...params }: TGetAnomalyCalendarParams) => [
  ...createObjectivesKey(),
  'anomaly-calendar',
  store_id,
  params,
];
export const createLogisticAnomaliesKey = ({ store_id, ...params }: TGetLogisticAnomaliesParams) => [
  ...createObjectivesKey(),
  'logistic-anomalies',
  store_id,
  params,
];

export const createObjectivesProgressKey = () => [...createObjectivesKey(), 'progress'];
export const createObjectivesTargetValueKey = () => [...createObjectivesProgressKey(), 'objective-details'];
export const createObjectiveParamsKey = ({ area_id, tag }: TGetObjectiveParams) => [
  ...createObjectivesTargetValueKey(),
  tag,
  area_id,
];
export const createAllObjectivesKey = () => [...createObjectivesProgressKey(), 'all-objectives'];
export const createAllObjectivesParamsKey = ({ area_id, tag }: TGetAllObjectivesParams) => [
  ...createAllObjectivesKey(),
  tag,
  area_id,
];

export const createInsightsKey = (params: TGetInsightsParams) => ['insights', params];

export const createUserKey = () => ['user'];
export const createUserListKey = () => [...createUserKey(), 'list'];
export const createUserDetailsKey = ({ userId }: TGetUserDetailsParams) => [...createUserKey(), 'details', userId];

export const createUserDeleteKey = ({ userId }: TDeleteUserParams) => ['deleted-user', userId];

export const createUserTypesKey = () => ['user-types'];

export const createCustomerLabelsKey = (params: TGetCustomerLabelsParams) => ['customer', 'labels', params];
export const createCustomerTiersKey = (params: TGetCustomerTiersParams) => ['customer', 'tiers', params];
export const createTopCustomersKey = (params: TGetTopCustomersParams) => ['customer', 'top', params];
export const createCustomersMapKey = (params: TGetCustomersMapParams) => [
  'customer',
  'category',
  'map',
  params.store_id,
];
export const createCustomerBeheviorMapKey = (params: TGetCustomersMapParams) => [
  'customer',
  'behevior',
  'map',
  params.store_id,
];
export const createCustomerMapExportKey = (params: TPostCreateCustomersMapExportParams) => [
  'customer',
  'map',
  'create',
  'xlsx',
  params,
];
export const createCustomerRetentionRateKey = (params: TGetCustomersRetentionRateParams) => [
  'customer',
  'retention-rate',
  params,
];
export const createCustomerGroupsRiskKey = (params: TGetCustomerGroupsRiskParams) => [
  'customer',
  'risk-groups',
  params,
];
export const createNeoEmailMapMainKey = () => ['neoemail', 'customer', 'category', 'map'];
export const createNeoEmailCustomersMapKey = (params: Partial<GetCustomersSimilarityParams>) => [
  ...createNeoEmailMapMainKey(),
  params.store_id,
  params.product_code,
];
export const createCustomerChurnKey = (params: TGetCustomersChurnParams) => ['customer', 'churn', params];
export const createCustomerLostGroupsKey = (params: TGetCustomersLostGroupsParams) => [
  'customer',
  'lost-groups',
  params,
];

export const createSellsWatchlistsKey = () => ['sells-wishlists'];
export const createSingleSellsWatchlistKey = (id: TSalesWatchlistId) => [...createSellsWatchlistsKey(), id];
export const createSalesWatchlistChartDataKey = (params: SalesWatchlistStatisticsParams) => [
  ...createSingleSellsWatchlistKey(params.watchlistId || 0),
  { date_from: params.date_from, date_to: params.date_to },
];

export const createSalesPredictionKey = (params: SalesPredictionParams) => ['sales-prediction', params];
export const createProductFamiliesStats = () => ['product-families-stats'];

export const createGoodToHaveKey = (params: TGetGoodToHaveForStores) => ['good-to-have', params];

export const createSimilarStoresKey = (params: TGetSimilarStoresParams) => ['similar-stores', params];

export const createCashiersPredictionKey = (params: CashierPredictionParams) => ['cashiers-prediction', params];

export const createCustomersPredictionKey = (params: SectionPredictionParams) => ['customers-prediction', params];

export const createGrossIncomePredictionKey = (params: SectionPredictionParams) => ['gross-income-prediction', params];

export const createStockDataKey = ({ column_sort, order, ...rest }: GetStocksForStore) => [
  'stock',
  'store-data',
  column_sort,
  order,
  rest,
];

export const getStockPointsList = (params: StocksPointsParams) => ['stock-points', params];
export const createProductStockDataKey = (params: GetStockProductHistory) => ['stock', 'product-code-data', params];
export const createNewProductsList = (root_family_code: TFamilyCode, limit = 10) => [
  'free-task-new-products',
  root_family_code,
  limit,
];

export const createGroupedOrdersKey = (params: OrdersParams) => [
  'grouped-orders',
  params.store_ids,
  params.section_ids,
];

export const createSuppliersListKey = () => ['all-suppliers'];

export const createNeoEmailCampaigneHistoryKey = (params: GetHistoryParams) => ['neo-email-campaigne-history', params];

export const createNeoEmailFindProducts = (params: FindProductsParams) => ['neo-email-find-products', params];

export const createShortageProductPointsKey = (params: ShortageProductPointsParams) => [
  'shortage-product-points',
  params,
];

export const createIndividualShortagePointsKey = (params: IndividualShortagesChartPointsParams) => [
  'individual-shortages-points',
  params,
];

export const createShortageProductsListKey = (params: ShortageProductsListParams) => ['shortage-products-list', params];

export const createAssortmentAnalysisListKey = (params: Commons) => ['assortment-analysis-list', params];

export const createMonthProductStatisticsKey = (params: TProductStatisticsRequestQueryParams) => [
  'product-month-statistics',
  params,
];

export const createWeekProductStatisticsKey = (params: TProductStatisticsRequestQueryParams) => [
  'product-week-statistics',
  params,
];

export const createSalesAnomalyProductKey = (params: TProductAnomaliesRequestQueryParams) => [
  'product-sales-anomalies',
  params,
];

export const createNeochatExampleQueriesKey = (params: TNeochatExampleQueriesParams) => [
  'neochat',
  'example-queries',
  params,
];

export const createStoresGroupsListKey = () => ['stores-groups-list'];

export const createWeeklyProductManagement = (params: TWeeklyProductManagementParams) => [
  'assortment-analysis',
  'weekly-product-management',
  params,
];

export const createStoreBudgetInfoKey = (params: any) => ['store-budget-info', params];

export const createDiscountsListKey = (params: DiscountsListFilterParams) => ['discounts-list', params];

export const createAssortmentStatisticsKey = (params: TAssortmentStatisticsParams) => ['assortment-statistics', params];

export const createShelfPredictionKey = (params: TPostNextShelfHelperParams) => ['shelf-prediction', params];

export const createFinalizeShelfHelperKey = (params: TGetFinalizeShelfHelperParams) => ['final-shelf-helper', params];

export const createStepShelfHelperKey = (params: TGetFinalizeShelfHelperParams) => ['step-shelf-helper', params];

export const createShelfHelperDetailsKey = (params: TGetFinalizeShelfHelperParams) => ['detailed-shelf-helper', params];

export const createShelfHelperShelvesKey = () => ['shelf-helper-shelves'];
