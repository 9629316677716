import React from 'react';
import { GridItem } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export const ShelvesTableHeader: React.FC = () => {
  const { t } = useTranslation('whisperme');

  const tableHeadingStyles = {
    fontWeight: 'bold',
    bgColor: 'gray.100',
    textTransform: 'uppercase',
    fontSize: '12px',
    paddingTop: '8px',
    paddingBottom: '8px',
    textAlign: 'center',
  };

  return (
    <>
      <GridItem sx={tableHeadingStyles}>{t('SHELF_HELPER.SHELVES_TABLE.START_DATE')}</GridItem>
      <GridItem sx={tableHeadingStyles}>{t('SHELF_HELPER.SHELVES_TABLE.WEEK')}</GridItem>
      <GridItem sx={tableHeadingStyles}>{t('SHELF_HELPER.SHELVES_TABLE.DIMENSIONS')}</GridItem>
      <GridItem sx={tableHeadingStyles}>{t('SHELF_HELPER.SHELVES_TABLE.NUMBER_OF_PRODUCTS')}</GridItem>
      <GridItem sx={tableHeadingStyles}>{t('SHELF_HELPER.SHELVES_TABLE.PRODUCTS')}</GridItem>
      <GridItem sx={tableHeadingStyles}>{t('SHELF_HELPER.SHELVES_TABLE.OPTIMIZE_BY')}</GridItem>
      <GridItem sx={tableHeadingStyles}>{t('SHELF_HELPER.SHELVES_TABLE.WITH_DISCOUNTS')}</GridItem>
      <GridItem sx={tableHeadingStyles}></GridItem>
    </>
  );
};
