import React, { useEffect, useState } from 'react';
import { Box, Grid, GridItem, Skeleton } from '@chakra-ui/react';
import { TScenarioResponseValidatorDetails } from 'domain/shelf-helper';
import { ColorMapping } from './hooks/useColorManagement';

interface ShelfProps {
  experimentUUID: string;
  shelfSize: string;
  data: TScenarioResponseValidatorDetails;
  colorMapping: ColorMapping;
  emptyColor?: string;
}

export const Shelf: React.FC<ShelfProps> = ({ experimentUUID, shelfSize, data, colorMapping, emptyColor = '#eee' }) => {
  const [gridWithItems, setGrid] = useState<(string | null)[][]>([]);
  const [columns, rows] = shelfSize.split('x');

  useEffect(() => {
    if (experimentUUID.length === 0) {
      const grid: (string | null)[][] = Array.from({ length: parseInt(rows, 10) }, () =>
        Array(parseInt(columns, 10)).fill(null),
      );
      setGrid(grid);
    } else {
      if (!experimentUUID || !data) return;
      const { table, positions } = data;

      // Create a 2D grid filled with null
      const grid: (string | null)[][] = Array.from({ length: table.size.y }, () => Array(table.size.x).fill(null));

      // Fill the grid with positions
      positions.forEach((pos) => {
        const { x1, y1, x2, y2 } = pos.details.position;
        for (let y = y1 - 1; y < y2; y++) {
          for (let x = x1 - 1; x < x2; x++) {
            grid[y][x] = pos.id;
          }
        }
      });
      setGrid(grid);
    }
  }, [columns, data, rows, experimentUUID, shelfSize]);

  if (experimentUUID.length && !data) {
    return <Skeleton>Loading...</Skeleton>;
  }

  return (
    <Box>
      <Grid
        gridTemplateColumns={`repeat(${columns}, 1fr)`}
        gridTemplateRows={`repeat(${rows}, 1fr)`}
        justifyContent="center"
        alignItems="center"
        gap="1"
      >
        {gridWithItems.flat().map((cell, index) => (
          <GridItem
            key={`cell-${index}`}
            bgColor={cell !== null ? colorMapping[cell] : emptyColor}
            h="32px"
            mr={1}
            mb={1}
          />
        ))}
      </Grid>
    </Box>
  );
};
