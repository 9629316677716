import React from 'react';
import { GridItem } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ShelvesItem } from 'domain/shelf-helper';

type ShelvesTableRowCommonProps = {
  shelf: ShelvesItem;
};

export const ShelvesTableRowCommon: React.FC<ShelvesTableRowCommonProps> = ({ shelf }: { shelf: ShelvesItem }) => {
  const { t } = useTranslation('whisperme');

  const OPTIMIZE_BY = {
    number_of_sold_units: t('SHELF_HELPER.NUMBER_OF_SOLD_UNITS_LABEL'),
    sales: t('SHELF_HELPER.SALES_LABEL'),
    revenue: t('SHELF_HELPER.MARGIN_LABEL'),
  };

  const tableRowStyles = {
    fontSize: '14px',
    paddingTop: '4px',
    paddingBottom: '4px',
    textAlign: 'center',
  };

  return (
    <>
      <GridItem sx={tableRowStyles}>{shelf.start_date}</GridItem>
      <GridItem sx={tableRowStyles}>
        {shelf.last_step_no}/{shelf.number_of_steps}
      </GridItem>
      <GridItem sx={tableRowStyles}>{shelf.shelf_dimensions}</GridItem>
      <GridItem sx={tableRowStyles}>{shelf.number_of_products}</GridItem>
      <GridItem sx={{ ...tableRowStyles, textAlign: 'left' }}>{shelf.list_of_products.join(', ')}</GridItem>
      <GridItem sx={tableRowStyles}>{OPTIMIZE_BY[shelf.optimize_by]}</GridItem>
      <GridItem sx={tableRowStyles}>
        {shelf.discounts ? t('SHELF_HELPER.YES_LABEL') : t('SHELF_HELPER.NO_LABEL')}
      </GridItem>
    </>
  );
};
