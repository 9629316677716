import { API_BASE_URL } from 'constants/env';
import { pipe } from 'fp-ts/function';
import { createValidatedCancellableFetch, makeCancellableFetch, urlWithQueryParams } from 'utils/request';
import {
  FinilizeShelfHelperValidator,
  ScenarioResponseValidator,
  ShelfHelperInitParams,
  ShelfHelperShelvesValidator,
  TGetFinalizeShelfHelperParams,
  TPostNextShelfHelperParams,
  TScenarioResponseValidator,
  TShelvesItem,
} from './types';

const API_URLS = {
  getShelves: () => `${API_BASE_URL}/shelf_helper/experiments`,
  postInit: () => `${API_BASE_URL}/shelf_helper/init`,
  getDelete: () => `${API_BASE_URL}/shelf_helper/experiments`,
  nextShelfPredictions: () => `${API_BASE_URL}/shelf_helper/step`,
  getFinalize: () => `${API_BASE_URL}/shelf_helper/fin`,
};

export const postNextShelfPredictions = (params: TPostNextShelfHelperParams) =>
  pipe(API_URLS.nextShelfPredictions(), (url) =>
    makeCancellableFetch<TScenarioResponseValidator>(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    }),
  );

export const postInit = (params: ShelfHelperInitParams) =>
  pipe(API_URLS.postInit(), (url) =>
    makeCancellableFetch<TScenarioResponseValidator>(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    }),
  );

export const getFinalizeShelfHelper = (queryParams: TGetFinalizeShelfHelperParams) =>
  pipe(
    API_URLS.getFinalize(),
    urlWithQueryParams(queryParams),
    createValidatedCancellableFetch(FinilizeShelfHelperValidator),
  );

export const getShelves = () =>
  pipe(API_URLS.getShelves(), createValidatedCancellableFetch(ShelfHelperShelvesValidator));

export const getStepShelfHelper = (queryParams: TGetFinalizeShelfHelperParams) =>
  pipe(
    API_URLS.nextShelfPredictions(),
    urlWithQueryParams(queryParams),
    createValidatedCancellableFetch(ScenarioResponseValidator),
  );

export const deleteShelf = (queryParams: TGetFinalizeShelfHelperParams) =>
  makeCancellableFetch(pipe(API_URLS.getDelete(), urlWithQueryParams(queryParams)), {
    method: 'DELETE',
  });

export const getStepShelfHelperDetails = (queryParams: TGetFinalizeShelfHelperParams) =>
  pipe(API_URLS.getShelves(), urlWithQueryParams(queryParams), createValidatedCancellableFetch(TShelvesItem));
