/* eslint-disable sonarjs/cognitive-complexity */
import React from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { useStoresData } from 'hooks/data';
import { DashboardLayout, TopbarHeading } from 'components/Dashboard';
import * as PATHS from 'constants/paths';
import { useScrollOnNavigation, useUserContext } from 'hooks';
import { SettingsPage } from './SettingsPage';
import { LoginPage } from './LoginPage';
import { MatchUserAccess } from 'components/Permissions';
import { InsightsPage } from './InsightsPage';
import { hasMonitorAccess, hasSectionManagerAccess, hasSupermarketManagerAccess } from 'domain/user';
import { CardErrorBoundary } from 'components/ErrorMessages';
import MorningTasksPage from './MorningTasksPage';
import DailyTasksPage from './DailyTasksPage';
import FreeTasksPage from './FreeTasksPage';
import { NeoEmailPage } from './NeoEmail';
import { NeoChatPage } from './NeoChat';
import ShoppingPage from './ShoppingPage';
import { AssortmentManagementPage } from './AssortmentManagementPage';
import { ShelfHelperPage } from './ShelfHelper/ShelfHelperPage';

const AnalyticsPage = React.lazy(() => import('./AnalyticsPage'));

export const ProtectedPages: React.FC = () => {
  const { t } = useTranslation('whisperme');
  const { user } = useUserContext();
  useStoresData();

  const isStoreManager = hasSupermarketManagerAccess(user);
  const isSectionManager = hasSectionManagerAccess(user);
  const isMonitor = hasMonitorAccess(user);
  const isMonitorOrSectionManager = isSectionManager || isMonitor;
  const redirect =
    isStoreManager || isSectionManager ? (
      <Redirect to={PATHS.MORNING_TASKS.ROOT.PATH} />
    ) : (
      <Redirect to={PATHS.SETTINGS.ROOT.PATH} />
    );

  return (
    <DashboardLayout>
      <Switch>
        <Route path={PATHS.MORNING_TASKS.ROOT.PATH}>
          {isStoreManager || isSectionManager ? (
            <>
              <TopbarHeading>{t('GENERIC.NAVIGATION.MORNING_TASKS_LABEL')}</TopbarHeading>
              <MorningTasksPage />
            </>
          ) : null}
        </Route>
        <Route path={PATHS.DAILY_TASKS.ROOT.PATH}>
          {isStoreManager || isSectionManager ? (
            <>
              <TopbarHeading>{t('GENERIC.NAVIGATION.DAILY_TASKS_LABEL')}</TopbarHeading>
              <DailyTasksPage />
            </>
          ) : null}
        </Route>
        <Route path={PATHS.FREE_TASKS.ROOT.PATH}>
          {isStoreManager || isSectionManager ? (
            <>
              <TopbarHeading>{t('GENERIC.NAVIGATION.FREE_TASKS_LABEL')}</TopbarHeading>
              <FreeTasksPage />
            </>
          ) : null}
        </Route>
        <Route path={PATHS.ASSORTMENT_MANAGEMENT.ROOT.PATH}>
          {isMonitorOrSectionManager ? (
            <>
              <TopbarHeading>{t('GENERIC.NAVIGATION.ASSORTMENT_MANAGEMENT.ROOT_LABEL')}</TopbarHeading>
              <AssortmentManagementPage />
            </>
          ) : null}
        </Route>
        <Route path={PATHS.INSIGHTS.ROOT.PATH}>
          <Redirect to={PATHS.MORNING_TASKS.ROOT.PATH} />
          <TopbarHeading>{t('GENERIC.NAVIGATION.INSIGHTS_LABEL')}</TopbarHeading>
          <CardErrorBoundary>
            <InsightsPage />
          </CardErrorBoundary>
        </Route>

        <Route path={PATHS.ANALYTICS.ROOT.PATH}>
          <MatchUserAccess
            anyAccess={
              <>
                <TopbarHeading>{t('GENERIC.NAVIGATION.ANALYTICS.ROOT_LABEL')}</TopbarHeading>
                <AnalyticsPage />
              </>
            }
            admin={() => null}
          />
        </Route>
        <Route path={PATHS.SETTINGS.ROOT.PATH}>
          <MatchUserAccess
            anyAccess={
              <>
                <TopbarHeading>{t('GENERIC.NAVIGATION.SETTINGS.ROOT_LABEL')}</TopbarHeading>
                <CardErrorBoundary>
                  <SettingsPage />
                </CardErrorBoundary>
              </>
            }
          />
        </Route>
        <Route path={PATHS.SHELF_HELPER.ROOT.PATH}>
          <MatchUserAccess
            anyAccess={
              <>
                <TopbarHeading>{t('GENERIC.NAVIGATION.SHELF_HELPER.ROOT_LABEL')}</TopbarHeading>
                <CardErrorBoundary>
                  <ShelfHelperPage />
                </CardErrorBoundary>
              </>
            }
          />
        </Route>
        <Route path={PATHS.LLM.NEOEMAIL.ROOT.PATH}>
          {isStoreManager || isSectionManager ? (
            <>
              <TopbarHeading>{t('GENERIC.NAVIGATION.LLM.NEOEMAIL.LABEL')}</TopbarHeading>
              <CardErrorBoundary>
                <NeoEmailPage />
              </CardErrorBoundary>
            </>
          ) : null}
        </Route>
        <Route path={PATHS.LLM.SHOPPING.ROOT.PATH}>
          {isStoreManager || isSectionManager ? (
            <>
              <TopbarHeading>{t('GENERIC.NAVIGATION.LLM.SHOPPING.ROOT_LABEL')}</TopbarHeading>
              <CardErrorBoundary>
                <ShoppingPage />
              </CardErrorBoundary>
            </>
          ) : null}
        </Route>
        <Route path={PATHS.LLM.NEOCHAT.ROOT.PATH}>
          {isStoreManager || isSectionManager ? (
            <>
              <TopbarHeading>{t('GENERIC.NAVIGATION.LLM.NEOCHAT.LABEL')}</TopbarHeading>
              <CardErrorBoundary>
                <NeoChatPage />
              </CardErrorBoundary>
            </>
          ) : null}
        </Route>
        {redirect}
      </Switch>
    </DashboardLayout>
  );
};

export const PublicPages = () => {
  const { t } = useTranslation('whisperme');

  return (
    <Switch>
      <Route path={PATHS.AUTH.LOGIN.PATH}>
        <Helmet>
          <title>{t('LOGIN_PAGE.PAGE_TITLE')}</title>
        </Helmet>
        <LoginPage />
      </Route>
      <Redirect to={PATHS.AUTH.LOGIN.PATH} />
    </Switch>
  );
};

export const Pages: React.FC = () => {
  useScrollOnNavigation();

  return <MatchUserAccess noAccess={<PublicPages />} anyAccess={<ProtectedPages />} />;
};
