import React from 'react';
import { Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface ShelfHeaderProps {
  experimentUUID: string;
  scenarioName?: string;
  duration?: number;
  totalDuration?: number;
  summaryVisible?: boolean;
}

export const ShelfHeader: React.FC<ShelfHeaderProps> = ({
  experimentUUID,
  scenarioName,
  duration = 0,
  totalDuration = 0,
  summaryVisible = false,
}: ShelfHeaderProps) => {
  const { t } = useTranslation('whisperme');

  if (!experimentUUID) {
    return null;
  }

  if (summaryVisible) {
    return (
      <Text align="right">
        <Text as="strong">{t('SHELF_HELPER.OPTIMAL_SHELF_LAYOUT_LABEL')}:</Text> {scenarioName}
      </Text>
    );
  }

  return (
    <Text align="right">
      <Text as="strong">{t('SHELF_HELPER.SHELF_DISTRIBUTION_LABEL')}:</Text> {scenarioName} - Week{' '}
      {totalDuration - duration + 1}/{totalDuration}
    </Text>
  );
};
