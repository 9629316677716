import * as PATHS from 'constants/paths';
import { createNavigationMatch } from 'utils/router';
import { NavigationMatch } from './types';

export const findNavigationMatch = createNavigationMatch({
  matchMapping: [
    [NavigationMatch.MORNING_TASKS, PATHS.MORNING_TASKS.ROOT.PATH],
    [NavigationMatch.DAILY_TASKS, PATHS.DAILY_TASKS.ROOT.PATH],
    [NavigationMatch.FREE_TASKS, PATHS.FREE_TASKS.ROOT.PATH],
    [NavigationMatch.ASSORTMENT_MANAGEMENT, PATHS.ASSORTMENT_MANAGEMENT.ROOT.PATH],
    [NavigationMatch.MAP, PATHS.MAP.ROOT.PATH],
    [NavigationMatch.INSIGHTS, PATHS.INSIGHTS.ROOT.PATH],
    [NavigationMatch.ANALYTICS, PATHS.ANALYTICS.ROOT.PATH],
    [NavigationMatch.SETTINGS, PATHS.SETTINGS.ROOT.PATH],
    [NavigationMatch.SHELF_HELPER, PATHS.SHELF_HELPER.ROOT.PATH],
    [NavigationMatch.NEOEMAIL, PATHS.LLM.NEOEMAIL.ROOT.PATH],
    [NavigationMatch.SHOPPING, PATHS.LLM.SHOPPING.ROOT.PATH],
  ],
  defaultValue: NavigationMatch.NONE,
});
