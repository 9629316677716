import React from 'react';
import { ShelfHelperInit } from 'components/ShelfHelper/ShelfHelperInit';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ShelfHelperFin } from 'components/ShelfHelper/ShelfHelperFin';
import { ShelfHelperStep } from 'components/ShelfHelper/ShelfHelperStep';
import { useTranslation } from 'react-i18next';
import { TopbarHeading } from 'components/Dashboard';
import * as PATHS from 'constants/paths';

import { Shelves } from 'components/ShelfHelper/Shelves';

export const ShelfHelperPage: React.FC = () => {
  const { t } = useTranslation('whisperme');

  return (
    <Switch>
      <Route path={PATHS.SHELF_HELPER.ROOT.PATH} exact={true}>
        <TopbarHeading>{[t('SHELF_HELPER.ROOT_LABEL'), t('SHELF_HELPER.SHELVES_PAGE_TITLE')]}</TopbarHeading>
        <Shelves />
      </Route>
      <Route path={PATHS.SHELF_HELPER.ROOT_INIT.PATH} exact={false}>
        <TopbarHeading>{[t('SHELF_HELPER.ROOT_LABEL'), t('SHELF_HELPER.INIT_PAGE_TITLE')]}</TopbarHeading>
        <ShelfHelperInit />
      </Route>
      <Route path={PATHS.SHELF_HELPER.ROOT_STEP.PATH}>
        <TopbarHeading>{[t('SHELF_HELPER.ROOT_LABEL'), t('SHELF_HELPER.STEP_PAGE_TITLE')]}</TopbarHeading>
        <ShelfHelperStep />
      </Route>
      <Route path={PATHS.SHELF_HELPER.ROOT_SUMMARY.PATH} exact={false}>
        <TopbarHeading>{[t('SHELF_HELPER.ROOT_LABEL'), t('SHELF_HELPER.SUMMARY_PAGE_TITLE')]}</TopbarHeading>
        <ShelfHelperFin />
      </Route>
      <Redirect to={PATHS.SHELF_HELPER.ROOT.toPath()} />
    </Switch>
  );
};
