import { useMemo } from 'react';
import { TScenarioResponseValidatorDetails } from 'domain/shelf-helper';

// Default colors if not provided via props
const DEFAULT_COLORS = ['#ffadad', '#ffd6a5', '#fdffb6', '#caffbf', '#9bf6ff', '#a0c4ff', '#bdb2ff', '#ffc6ff'];

interface UseColorManagementProps {
  data?: TScenarioResponseValidatorDetails;
  colors?: string[];
}

export type ColorMapping = Record<string, string>;

export const useColorManagement = ({ data, colors = DEFAULT_COLORS }: UseColorManagementProps) => {
  return useMemo(() => {
    if (!data || !data.positions) {
      return {} as ColorMapping;
    }

    const newColorMapping: Record<string, string> = {};

    data.positions.forEach((pos, index) => {
      if (!newColorMapping[pos.id]) {
        newColorMapping[pos.id] = colors[index % colors.length]; // Assign cyclic color
      }
    });

    return newColorMapping;
  }, [data, colors]);
};
