const ROOT_PATH = '/shelf-helper' as const;

export const ROOT = {
  PATH: ROOT_PATH,
  toPath: () => ROOT_PATH,
};

const ROOT_PATH_INIT = `${ROOT_PATH}/init` as const;

export const ROOT_INIT = {
  PATH: ROOT_PATH_INIT,
  toPath: () => ROOT_PATH_INIT,
};

const ROOT_PATH_STEP = `${ROOT_PATH}/step` as const;

export const ROOT_STEP = {
  PATH: ROOT_PATH_STEP,
  toPath: () => ROOT_PATH_STEP,
};

const ROOT_PATH_SUMMARY = `${ROOT_PATH}/summary` as const;

export const ROOT_SUMMARY = {
  PATH: ROOT_PATH_SUMMARY,
  toPath: () => ROOT_PATH_SUMMARY,
};
