import React, { useMemo } from 'react';
import { Card, CardTitleSection } from 'components/Card';
import { Button, Grid, Skeleton, Text, useToast } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import * as PATHS from 'constants/paths';
import { useShelfHelperShelves } from 'pages/ShelfHelper/hook/data';
import { ShelvesByState, ShelvesItem } from 'domain/shelf-helper/types';
import { useTranslation } from 'react-i18next';
import { ShelvesTableHeader } from './ShelvesTableHeader';
import { ShelvesTableRowActive } from './ShelvesTableRowActive';
import { SHELVES_TABLE_COLUMNS_TEMPLATE } from './consts';
import { ShelvesTableRowFinished } from './ShelvesTableRowFinished';
import { useMutation, useQueryClient } from 'react-query';
import { createShelfHelperShelvesKey } from 'constants/queryCacheKeys';
import { deleteShelf } from 'domain/shelf-helper';

export const Shelves: React.FC = () => {
  const queryClient = useQueryClient();
  const { data, isLoading } = useShelfHelperShelves();
  const toast = useToast();
  const history = useHistory();
  const { t } = useTranslation('whisperme');

  const shelves: ShelvesByState | undefined = useMemo(() => {
    if (!data) return;
    const experiments: ShelvesByState = {
      active: [],
      finished: [],
    };
    data.map((shelf) => experiments[shelf.status].push(shelf));
    return experiments;
  }, [data]);

  const { mutate: mutateDelete } = useMutation(deleteShelf, {
    onSuccess: () => {
      toast({
        title: t('SHELF_HELPER.SHELVES.SHELF_DELETE_SUCCESS'),
        status: 'success',
        duration: 3000,
        position: 'top',
        isClosable: true,
      });
      queryClient.invalidateQueries(createShelfHelperShelvesKey());
    },
    onError: () => {
      toast({
        title: t('SHELF_HELPER.SHELVES.SHELF_DELETE_ERROR'),
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },
  });

  if (isLoading) {
    return (
      <Card>
        <CardTitleSection as="h2">{t('SHELF_HELPER.ROOT_LABEL')}</CardTitleSection>
        <Grid templateColumns="1fr" width="100%" height="48vh">
          <Skeleton height="192px" />
          <Skeleton height="192px" />
          <Skeleton height="192px" />
        </Grid>
      </Card>
    );
  }

  return (
    <Card>
      <Button
        colorScheme="blue"
        size="sm"
        onClick={() => {
          history.push(PATHS.SHELF_HELPER.ROOT_INIT.toPath());
        }}>
        {t('SHELF_HELPER.OPTIMIZE_NEW_SHELF')}
      </Button>

      <Text as="h2" fontWeight="bold" mt={2}>
        {t('SHELF_HELPER.ONGOING_OPTIMIZATIONS_LABEL')}
      </Text>

      <Grid templateColumns={SHELVES_TABLE_COLUMNS_TEMPLATE} mt={2}>
        <ShelvesTableHeader />
        {shelves?.active.map((shelf: ShelvesItem) => (
          <ShelvesTableRowActive shelf={shelf} key={shelf.id} onDelete={mutateDelete} />
        ))}
      </Grid>

      <Text as="h2" fontWeight="bold" mt={2}>
        {t('SHELF_HELPER.FINISHED_OPTIMIZATIONS_LABEL')}
      </Text>

      <Grid templateColumns={SHELVES_TABLE_COLUMNS_TEMPLATE} mt={2}>
        <ShelvesTableHeader />
        {shelves?.finished.map((shelf: ShelvesItem) => (
          <ShelvesTableRowFinished shelf={shelf} key={shelf.id} onDelete={mutateDelete} />
        ))}
      </Grid>
    </Card>
  );
};
