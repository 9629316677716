import React from 'react';
import { Select } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface ShelfSizeSelectProps {
  shelfSize: string;
  setShelfSize: (val: string) => void;
  disabled?: boolean;
}

export const ShelfSizeSelect: React.FC<ShelfSizeSelectProps> = ({
  shelfSize,
  setShelfSize,
  disabled = false,
}: ShelfSizeSelectProps) => {
  const { t } = useTranslation('whisperme');

  return (
    <Select
      placeholder={t('SHELF_HELPER.SHELF_SIZE')}
      value={shelfSize}
      onChange={(e) => setShelfSize(e.target.value)}
      disabled={disabled}
    >
      <option value="40x5">40x5</option>
      <option value="20x6">20x6</option>
    </Select>
  );
};
