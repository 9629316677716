import { createFinalizeShelfHelperKey } from 'constants/queryCacheKeys';
import { getFinalizeShelfHelper, TPostNextShelfHelperParams } from 'domain/shelf-helper';
import { useQuery } from 'react-query';

export const useFinalShelfHelper = (params: Partial<TPostNextShelfHelperParams>) => {
  const isEnabled = !!(params.experiment_id ?? '').length;

  const fullParams = { experiment_id: params.experiment_id ?? '' };

  return useQuery({
    queryKey: createFinalizeShelfHelperKey(fullParams),
    queryFn: () => getFinalizeShelfHelper(fullParams),
    enabled: isEnabled,
  });
};
