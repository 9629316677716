import * as t from 'io-ts';
import { optionFromNullable } from 'io-ts-types';

export type TUnitsSold = Record<string, number>;

export type TPostNextShelfHelperParams = {
  experiment_id: string;
  sales?: TUnitsSold;
};

export type TGetFinalizeShelfHelperParams = {
  experiment_id: string;
};

export const TableSize = t.type({
  x: t.number,
  y: t.number,
});

export const Table = t.type({
  size: TableSize,
});

export const Position = t.type({
  x1: t.number,
  y1: t.number,
  x2: t.number,
  y2: t.number,
});

export const PositionDetails = t.type({
  position: Position,
});

export const PositionedItem = t.type({
  id: t.string,
  details: PositionDetails,
});

export const ProductDescription = t.type({
  id: t.string,
  name: t.string,
  org_price: t.number, // Kept as string since "org_price" is quoted in the data
  org_margin: t.number,
  discount: t.number,
  new_price: t.number,
  distribution: t.number,
});

export const ScenarioResponseValidatorDetails = t.type({
  table: Table,
  name: t.string,
  positions: t.array(PositionedItem),
  description: t.array(ProductDescription),
});

export type TScenarioResponseValidatorDetails = t.TypeOf<typeof ScenarioResponseValidatorDetails>;

export type ShelvesItem = {
  id: string;
  status: Status;
  last_step_no: number;
  number_of_steps: number;
  shelf_dimensions: string;
  number_of_products: number;
  list_of_products: string[];
  optimize_by: OptimizeBy;
  start_date: string;
  discounts: boolean;
};

export type ShelvesByState = {
    active: ShelvesItem[];
    finished: ShelvesItem[];
  };

export const TShelvesItem = t.type({
  id: t.string,
  status: t.union([t.literal('active'), t.literal('finished')]),
  last_step_no: t.number,
  number_of_steps: t.number,
  shelf_dimensions: t.string,
  number_of_products: t.number,
  list_of_products: t.array(t.string),
  optimize_by: t.union([t.literal('number_of_sold_units'), t.literal('sales'), t.literal('revenue')]),
  start_date: t.string,
  discounts: t.boolean,
});

export const ShelfHelperShelvesValidator = t.array(TShelvesItem);
export type TShelfHelperShelvesValidator = t.TypeOf<typeof ShelfHelperShelvesValidator>;

export const ScenarioResponseValidator = t.type({
  response: ScenarioResponseValidatorDetails,
  experiment_id: t.string,
  from_list: TShelvesItem,
});

export type TScenarioResponseValidator = t.TypeOf<typeof ScenarioResponseValidator>;

export const ShelfProduct = t.type({
  id: t.number,
  name: t.string,
  discount: t.number,
  distribution: t.number,
  new_price: t.number,
  org_margin: t.number,
  org_price: t.string,
  units_sold: optionFromNullable(t.number),
});

export const InitialShelfProduct = t.type({
  name: t.string,
  discount: t.number,
  org_price: t.number,
  org_margin: t.number,
});

export const ShelfProducts = t.array(ShelfProduct);
export const IntialShelfProducts = t.array(InitialShelfProduct);

export type TShelfProduct = t.TypeOf<typeof ShelfProduct>;
export type TShelfProducts = t.TypeOf<typeof ShelfProducts>;
export type TInitiaShelfProducts = t.TypeOf<typeof IntialShelfProducts>;

export type ShelfHelperInitProduct = {
  name: string;
  discount: number;
  org_price: number;
  org_margin: number;
};

export type ShelfHelperInitParams = {
  no_of_product: number;
  shelf_size: string;
  optiomalize: string;
  discounts: boolean;
  products: ShelfHelperInitProduct[];
  duration: number;
};

export const ResultsShelfHelperWeekStat = t.type({
  week: t.number,
  amount: t.number,
  cumulative_amount: t.number,
  sales: t.number,
  cumulative_sales: t.number,
  scenario: t.string,
});

export const ResultsShelfHelperWeekStats = t.array(ResultsShelfHelperWeekStat);

export type TResultsShelfHelperWeekStat = t.TypeOf<typeof ResultsShelfHelperWeekStat>;
export type TResultsShelfHelperWeekStats = t.TypeOf<typeof ResultsShelfHelperWeekStats>;

export const FinilizeShelfHelperValidator = t.type({
  output: t.string,
  response: ScenarioResponseValidatorDetails,
  chart: ResultsShelfHelperWeekStats,
});

export type TFinilizeShelfHelper = t.TypeOf<typeof FinilizeShelfHelperValidator>;

export type OptimizeBy = 'number_of_sold_units' | 'sales' | 'revenue';
export type Status = 'active' | 'finished';
