import { Card } from 'components/Card';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { ShelfHelperSummary } from './ShelfHelperSummary';

export const ShelfHelperFin: React.FC = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const experimentId = searchParams.get('experimentId') ?? '';

  if (!experimentId) {
    return null;
  }

  return (
    <Card>
      <ShelfHelperSummary experimentId={experimentId} />
    </Card>
  );
};
