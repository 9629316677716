import React from 'react';
import { Select } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface UniqueProductsSelectProps {
  numberOfProducts: number;
  setNumberOfProducts: (val: number) => void;
  disabled?: boolean;
}

const INITIAL_PRODUCTS = [4, 6, 8];

export const UniqueProductsSelect: React.FC<UniqueProductsSelectProps> = ({
  numberOfProducts,
  setNumberOfProducts,
  disabled = false,
}: UniqueProductsSelectProps) => {
  const { t } = useTranslation('whisperme');

  return (
    <Select
      placeholder={t('SHELF_HELPER.NUMBER_OF_UNIQUE_PRODUCTS')}
      onChange={(e) => setNumberOfProducts(parseInt(e.target.value, 10))}
      value={numberOfProducts}
      disabled={disabled}
    >
      {Array.from(INITIAL_PRODUCTS, (i) => (
        <option value={i} key={`numberOfProducts-${i}`}>
          {i} products
        </option>
      ))}
    </Select>
  );
};
