import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Flex, Grid, GridItem, Input, Skeleton, Spinner, Text, useToast } from '@chakra-ui/react';
import { Shelf } from 'components/ShelfHelper/Shelf';
import { useTranslation } from 'react-i18next';
import { Card, CardTitleSection } from 'components/Card';
import { EuroSign } from '../Icons/icons';
import * as PATHS from 'constants/paths';
import {
  postNextShelfPredictions,
  TInitiaShelfProducts,
  TScenarioResponseValidatorDetails,
  TUnitsSold,
} from 'domain/shelf-helper';
import { useColorManagement } from './hooks/useColorManagement';
import { useHistory, useLocation } from 'react-router-dom';
import { useShelfPrediction } from 'hooks/data/useShelfPrediction';
import { createShelfHelperShelvesKey } from 'constants/queryCacheKeys';
import { useQueryClient } from 'react-query';

const INIT_SHELF_PRODUCT = [
  {
    name: 'Product 1',
    discount: 0,
    org_price: 5,
    org_margin: 1,
  },
  {
    name: 'Product 2',
    discount: 0,
    org_price: 5,
    org_margin: 1,
  },
  {
    name: 'Product 3',
    discount: 0,
    org_price: 5,
    org_margin: 1,
  },
  {
    name: 'Product 4',
    discount: 0,
    org_price: 5,
    org_margin: 1,
  },
];

export const ShelfHelperStep: React.FC = () => {
  const { t } = useTranslation('whisperme');
  const queryClient = useQueryClient();
  const toast = useToast();
  const { search } = useLocation();
  const history = useHistory();

  const searchParams = new URLSearchParams(search);
  const experimentId = searchParams.get('experimentId') ?? '';

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [experimentUUID] = useState<string>(experimentId);
  const [products, setProducts] = useState<TInitiaShelfProducts>(INIT_SHELF_PRODUCT);
  const [shelfSize, setShelfSize] = useState<string>('');
  const [includeDiscounts, setIncludeDiscounts] = useState<boolean>(false);
  const [duration, setDuration] = useState<number>(4);
  const [totalDuration, setTotalDuration] = useState<number>(4);
  const [unitsSold, setUnitsSold] = useState<TUnitsSold>({});
  const [data, setData] = useState<TScenarioResponseValidatorDetails>(
    {} as unknown as TScenarioResponseValidatorDetails,
  );
  const colorMapping = useColorManagement({ data });
  const goBackLink = PATHS.SHELF_HELPER.ROOT.toPath();

  const { data: predictionDetails, isLoading: predictionLoading } = useShelfPrediction({ experiment_id: experimentId });

  const buttonLabel = useMemo(() => {
    return duration === totalDuration ? (
      t('SHELF_HELPER.SUMMARY_LABEL')
    ) : (
      <>
        {t('SHELF_HELPER.NEXT_WEEK_LABEL')} ({totalDuration - duration})
      </>
    );
  }, [duration, t, totalDuration]);

  const handleNextStep = () => {
    setIsLoading(true);

    const values = Object.values(unitsSold);

    if (values.length !== Object.values(products).length) {
      toast({
        title: t('COMPONENTS.ERROR_MESSAGES.PROMPT_TITLE_ERROR'),
        description: t('SHELF_HELPER.ERROR_MESSAGES.UNITS_SOLD_ERROR'),
        status: 'error',
      });
      setIsLoading(false);
      return;
    }

    const payload = {
      experiment_id: experimentUUID,
      sales: unitsSold,
    };

    postNextShelfPredictions(payload)
      .then((response) => {
        queryClient.invalidateQueries(createShelfHelperShelvesKey());
        if (!response) {
          history.push(`/shelf-helper/summary?experimentId=${experimentUUID}`);
        } else {
          if (response.experiment_id && response.response) {
            setData(response.response);
            setDuration(response.from_list.last_step_no);
            setProducts(response.response.description);
            setUnitsSold({});
          }
        }
      })
      .catch((err) => {
        console.log('err: ', err);
        toast({
          title: t('COMPONENTS.ERROR_MESSAGES.PROMPT_TITLE_ERROR'),
          description: t('SHELF_HELPER.ERROR_MESSAGES.EXPERIMENT_INIT_ERROR'),
          status: 'error',
        });
      })
      .finally(() => {
        setUnitsSold({});
        setIsLoading(false);
      });
  };

  const handleUnitsSoldChange = (productId: string, value: number) => {
    setUnitsSold((prev) => ({
      ...prev,
      [productId]: value,
    }));
  };

  useEffect(() => {
    if (!predictionDetails) return;

    setData(predictionDetails.response);
    setShelfSize(predictionDetails.from_list.shelf_dimensions);
    setTotalDuration(predictionDetails.from_list.number_of_steps);
    setDuration(predictionDetails.from_list.last_step_no);
    setIncludeDiscounts(predictionDetails.from_list.discounts);
    setProducts(predictionDetails.response.description);
  }, [
    predictionDetails?.response,
    predictionDetails?.response.description,
    predictionDetails?.from_list.shelf_dimensions,
    predictionDetails?.from_list.number_of_steps,
    predictionDetails?.from_list.last_step_no,
    predictionDetails?.from_list.discounts,
    shelfSize,
    predictionDetails,
  ]);

  const tableColumns = useMemo(() => {
    if (includeDiscounts) return '30px 2fr 1fr 1fr 1fr 1fr';
    return '30px 2fr 1fr 1fr';
  }, [includeDiscounts]);

  if (predictionLoading || !predictionDetails) {
    return (
      <Card>
        <Skeleton height="120px" />
      </Card>
    );
  }

  return (
    <Card>
      <CardTitleSection as="h2" backButtonLink={goBackLink}>
        {t('SHELF_HELPER.ROOT_LABEL')}
      </CardTitleSection>

      <Grid templateColumns="1fr" width="100%">
        <Text align="right">
          {t('SHELF_HELPER.SHELF_DISTRIBUTION_LABEL')}: {data.name} - Week {duration}/{totalDuration}
        </Text>
        {data && shelfSize ? (
          <Shelf experimentUUID={experimentUUID} shelfSize={shelfSize} data={data} colorMapping={colorMapping} />
        ) : null}
      </Grid>

      <Grid templateColumns={experimentUUID.length ? '12fr' : '9fr 3fr'} width="100%">
        <Box mt="3">
          <Grid templateColumns={tableColumns} gap={3} mt="3">
            <GridItem></GridItem>
            <GridItem as="strong">{t('SHELF_HELPER.PRODUCT_NAME_LABEL')}</GridItem>
            <GridItem as="strong">{t('SHELF_HELPER.PRODUCT_ORIGINAL_PRICE_LABEL')}</GridItem>

            {includeDiscounts ? (
              <>
                <GridItem as="strong">{t('SHELF_HELPER.DISCOUNT_LABEL')}</GridItem>
                <GridItem as="strong">{t('SHELF_HELPER.NEW_PRICE_LABEL')}</GridItem>
              </>
            ) : null}
            <GridItem as="strong">{t('SHELF_HELPER.SOLD_UNITS_LABEL')}</GridItem>

            {data.description &&
              data.description.map((item, index) => (
                <React.Fragment key={`product_${index}`}>
                  <GridItem>
                    <Box backgroundColor={colorMapping[item.id] || '#eee'} width="30px" height="30px"></Box>
                  </GridItem>
                  <GridItem>{item.name}</GridItem>
                  <GridItem>
                    {item.org_price} <EuroSign />
                  </GridItem>
                  {includeDiscounts ? (
                    <>
                      <GridItem>{item.discount} %</GridItem>
                      <GridItem>{item.new_price} €</GridItem>
                    </>
                  ) : null}

                  <GridItem>
                    <Input
                      type="number"
                      disabled={isLoading}
                      w="24"
                      value={unitsSold[item.id] ?? ''}
                      onChange={(e) =>
                        handleUnitsSoldChange(item.id, e.target.value ? parseInt(e.target.value, 10) : 0)
                      }></Input>
                  </GridItem>
                </React.Fragment>
              ))}
          </Grid>
        </Box>
      </Grid>
      <Flex mt="3" width="100%" justifyContent="flex-end">
        {isLoading ? <Spinner marginRight="16px" marginTop="4px" /> : null}
        <Button
          colorScheme="blue"
          size="sm"
          disabled={isLoading}
          onClick={() => {
            handleNextStep();
          }}>
          {buttonLabel}
        </Button>
      </Flex>
    </Card>
  );
};
