import React from 'react';
import { Grid, GridItem } from '@chakra-ui/react';
import { Card, CardTitleSection } from 'components/Card';
import { CheckboxChecked, CheckboxEmpty } from 'components/Icons';
import { useTranslation } from 'react-i18next';

const PRODUCTS = [
  {
    ean: '2870772000000',
    name: 'COTE DE BOEUF PROM',
    sales_last_week: '211€',
    sales_this_week: '84€',
    optimization_proposal: 'High-Traffic Zones Exposition',
    implemented: true,
  },
  {
    ean: '3245414667990',
    name: 'HACHE VRAC 500G',
    sales_last_week: '188€',
    sales_this_week: '53€',
    optimization_proposal: 'Eye-Level Shelf Placement',
    implemented: true,
  },
  {
    ean: '2407110000000',
    name: 'CHIPO X5',
    sales_last_week: '153€',
    sales_this_week: '43€',
    optimization_proposal: 'In-store Advertisement',
    implemented: true,
  },
  {
    ean: '3245415074209',
    name: 'HACHE VRAC 15%',
    sales_last_week: '150€',
    sales_this_week: '31€',
    optimization_proposal: 'Discounts',
    implemented: true,
  },
  {
    ean: '2459857000000',
    name: 'DESSU DE PALETTE',
    sales_last_week: '142€',
    sales_this_week: '44€',
    optimization_proposal: 'Eye-Level Shelf Placement',
    implemented: true,
  },
  {
    ean: '3245413750549',
    name: 'SH 5% CRF 2X125G',
    sales_last_week: '120€',
    sales_this_week: '61€',
    optimization_proposal: 'In-Store Demonstrations',
    implemented: true,
  },
];

export const SalesOptimizationCard: React.FC = () => {
  const { t } = useTranslation('whisperme');

  const tableHeadingStyles = {
    fontWeight: 'bold',
    bgColor: 'gray.100',
    textTransform: 'uppercase',
    fontSize: '12px',
    paddingTop: '8px',
    paddingBottom: '8px',
  };

  const tableRowStyles = {
    fontSize: '14px',
    paddingTop: '4px',
    paddingBottom: '4px',
  };

  return (
    <Card>
      <CardTitleSection as="h2">{t('SHELF_HELPER.PRODUCTS_SALES_OPTIMIZATION_TABLE.HEADER_TITLE')}</CardTitleSection>

      <Grid templateColumns="1fr 2fr repeat(2, 1fr) 2fr  1.5fr">
        <GridItem sx={tableHeadingStyles}>{t('SHELF_HELPER.PRODUCTS_SALES_OPTIMIZATION_TABLE.EAN')}</GridItem>
        <GridItem sx={tableHeadingStyles}>{t('SHELF_HELPER.PRODUCTS_SALES_OPTIMIZATION_TABLE.NAME')}</GridItem>
        <GridItem sx={tableHeadingStyles} textAlign="center">
          {t('SHELF_HELPER.PRODUCTS_SALES_OPTIMIZATION_TABLE.SALES_LAST_WEEK')}
        </GridItem>
        <GridItem sx={tableHeadingStyles} textAlign="center">
          {t('SHELF_HELPER.PRODUCTS_SALES_OPTIMIZATION_TABLE.SALES_THIS_WEEK')}
        </GridItem>
        <GridItem sx={tableHeadingStyles}>
          {t('SHELF_HELPER.PRODUCTS_SALES_OPTIMIZATION_TABLE.PROPOSAL_OF_OPTIMIZATION')}
        </GridItem>
        <GridItem sx={tableHeadingStyles} textAlign="center">
          {t('SHELF_HELPER.PRODUCTS_SALES_OPTIMIZATION_TABLE.WAS_IMPLEMENTED')}
        </GridItem>

        {PRODUCTS.map((product) => (
          <React.Fragment key={product.ean}>
            <GridItem sx={tableRowStyles}>{product.ean}</GridItem>
            <GridItem sx={tableRowStyles}>{product.name}</GridItem>
            <GridItem sx={tableRowStyles} textAlign="center">
              {product.sales_last_week}
            </GridItem>
            <GridItem sx={tableRowStyles} textAlign="center">
              {product.sales_this_week}
            </GridItem>
            <GridItem sx={tableRowStyles}>{product.optimization_proposal}</GridItem>
            <GridItem sx={tableRowStyles} textAlign="center">
              {product.implemented ? <CheckboxChecked /> : <CheckboxEmpty />}
            </GridItem>
          </React.Fragment>
        ))}
      </Grid>
    </Card>
  );
};
