import React from 'react';
import { GridItem, IconButton } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ShelvesItem, TGetFinalizeShelfHelperParams } from 'domain/shelf-helper';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { ShelvesTableRowCommon } from './ShelvesTableRowCommon';
import { useHistory } from 'react-router-dom';
import * as PATHS from 'constants/paths';

type ShelvesTableRowActiveProps = {
  shelf: ShelvesItem;
  onDelete: (param: TGetFinalizeShelfHelperParams) => void;
};

export const ShelvesTableRowActive: React.FC<ShelvesTableRowActiveProps> = ({ shelf, onDelete }) => {
  const { t } = useTranslation('whisperme');
  const history = useHistory();

  return (
    <>
      <ShelvesTableRowCommon shelf={shelf} />
      <GridItem textAlign="center">
        <IconButton
          aria-label={t('SHELF_HELPER.SHELVES.EDIT_SHELF_LABEL')}
          title={t('SHELF_HELPER.SHELVES.EDIT_SHELF_LABEL')}
          size="sm"
          variant="outline"
          onClick={() => {
            console.log(`${PATHS.SHELF_HELPER.ROOT_STEP.toPath()}?experimentId=${shelf.id}`);
            history.push(`${PATHS.SHELF_HELPER.ROOT_STEP.toPath()}?experimentId=${shelf.id}`);
          }}>
          <EditIcon />
        </IconButton>
        <IconButton
          aria-label={t('SHELF_HELPER.SHELVES.DELETE_SHELF_LABEL')}
          title={t('SHELF_HELPER.SHELVES.DELETE_SHELF_LABEL')}
          size="sm"
          variant="outline"
          onClick={() => {
            console.log('Just delete... with confirmation: ', shelf.id);
            onDelete({ experiment_id: shelf.id });
          }}>
          <DeleteIcon />
        </IconButton>
      </GridItem>
    </>
  );
};
