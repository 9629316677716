import { Flex, Stack, Text } from '@chakra-ui/react';
import { ChartData, ChartDataEntry, InteractiveChartMetric, LineChart } from 'components/Charts';
import { TResultsShelfHelperWeekStats } from 'domain/shelf-helper/types';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import React, { useMemo } from 'react';
import { Card, CardTitleSection } from 'components/Card';
import { RenderTooltipParams } from '@visx/xychart/lib/components/Tooltip';
import { useAppConfig } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Metrics } from './type';

const dataToMetrics =
  (metrics: Metrics) =>
  (data: TResultsShelfHelperWeekStats): ChartDataEntry[] => {
    return pipe(
      data,
      A.map((weekStat) => ({
        x: String(weekStat.week),
        y: [weekStat[metrics]],
      })),
    );
  };

const DIMENSIONS = {
  minWidth: '200px',
  minHeight: '300px',
};

type ShelfHelperSummaryGraphProps = {
  chartData: TResultsShelfHelperWeekStats;
  chartMetrics: Metrics;
};

export const ShelfHelperSummaryGraph: React.FC<ShelfHelperSummaryGraphProps> = ({
  chartData,
  chartMetrics = 'cumulative_sales',
}) => {
  const { t } = useTranslation('whisperme');
  const {
    formatters: { currencyFormatter },
  } = useAppConfig();

  const dataParser = useMemo(() => dataToMetrics(chartMetrics), [chartMetrics]);

  const data: ChartData = {
    data: dataParser(chartData),
    dataResolution: 'week',
    plotDataResolution: 'week',
    chartMetrics: pipe(
      [chartMetrics],
      A.mapWithIndex((idx, key) => {
        return {
          dataKey: String(key),
          name: key,
          index: idx,
        };
      }),
    ),
    xLabel: 'Week',
    type: 'line',
  };

  const renderTooltip = (
    { tooltipData, colorScale }: RenderTooltipParams<ChartDataEntry>,
    _metrics: InteractiveChartMetric[],
  ) => {
    const index = parseInt((tooltipData?.nearestDatum?.datum.x as string) || '-1', 10);

    return index !== -1 ? (
      <Stack>
        <Text>Week {tooltipData?.nearestDatum?.datum.x}</Text>
        <Stack>
          {Object.entries(chartData.find((values) => values.week === index) ?? {})
            .filter(([key]) => key !== 'week')
            .map(([key, value]) => {
              return (
                <Text key={key} as="span">
                  {key}:{' '}
                  <Text as="span" color={colorScale?.(key) || 'black'}>
                    {['sales', 'cumulative_sales'].includes(key)
                      ? currencyFormatter.format(Number(value))
                      : String(value)}
                  </Text>
                </Text>
              );
            })}
        </Stack>
      </Stack>
    ) : null;
  };

  return (
    <Card width="100%">
      <CardTitleSection>{t('SHELF_HELPER.SALES_RESULTS_LABEL')}</CardTitleSection>
      <Flex
        flexDirection="column"
        flex="1 1 auto"
        width="100%"
        minWidth={DIMENSIONS.minWidth}
        minHeight={DIMENSIONS.minHeight}
        height="44vh"
      >
        <LineChart
          curve="cardinal"
          chartData={data}
          renderTooltip={renderTooltip}
          showLabels={true}
          xLabel="Week"
          yAxisProps={{
            tickFormat: (value) =>
              chartMetrics === 'cumulative_amount' ? String(value) : currencyFormatter.format(Number(value)),
          }}
        />
      </Flex>
    </Card>
  );
};
