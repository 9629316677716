import React from 'react';
import { useTranslation } from 'react-i18next';
import { CardTitleSection } from 'components/Card';
import { Box, Grid, Skeleton } from '@chakra-ui/react';
import { ShelfHelperSummaryGraph } from './ShelfHelperSummaryGraph';
import { useFinalShelfHelper } from 'hooks/data/useFinalShelfHelper';
import { Shelf } from './Shelf';
import { ColorMapping, useColorManagement } from './hooks/useColorManagement';
import { Metrics } from './type';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { ShelfHeader } from './ShelfHeader';
import * as PATHS from 'constants/paths';

type ShelfHelperSummaryProps = {
  experimentId: string;
  variant?: Metrics;
};

export const ShelfHelperSummary: React.FC<ShelfHelperSummaryProps> = ({ experimentId, variant }) => {
  const { t } = useTranslation('whisperme');
  const { data: shelfHelperSummary, isLoading } = useFinalShelfHelper({ experiment_id: experimentId });
  const colorMapping: ColorMapping = useColorManagement({ data: shelfHelperSummary?.response });
  const goBackLink = PATHS.SHELF_HELPER.ROOT.toPath();

  return (
    <Box>
      <CardTitleSection as="h2" backButtonLink={goBackLink}>
        {t('SHELF_HELPER.SHELF_HELPER_SUMMARY_LABEL')}
      </CardTitleSection>
      <Grid templateColumns="1fr 1fr" gap={4} width="100%" height="88vh" overflow="hidden">
        {isLoading || !shelfHelperSummary ? (
          <>
            <Skeleton gridColumn="span 2" />
            <Skeleton />
            <Skeleton />
          </>
        ) : (
          <>
            <Box gridColumn="span 2">
              <ShelfHeader
                experimentUUID={experimentId}
                scenarioName={shelfHelperSummary.response.name}
                summaryVisible={true}
              />
              <Shelf
                experimentUUID={experimentId}
                shelfSize={Object.values(shelfHelperSummary?.response.table.size).reduce(
                  (acc, val, index) => (index ? acc + 'x' + val : acc + val),
                  '',
                )}
                data={shelfHelperSummary?.response}
                colorMapping={colorMapping}
              />
            </Box>
            <Box
              overflow="auto"
              paddingX={5}
              sx={{
                '& h1,h2,h3,h4,h5,h6,p,ul,ol': {
                  marginBottom: '2rem',
                },
              }}
              maxHeight="100%">
              <Markdown remarkPlugins={[remarkGfm]}>{shelfHelperSummary.output}</Markdown>
            </Box>
            <Box maxHeight="100%">
              <ShelfHelperSummaryGraph
                chartData={shelfHelperSummary.chart}
                chartMetrics={variant ?? 'cumulative_amount'}
              />
            </Box>
          </>
        )}
      </Grid>
    </Box>
  );
};
